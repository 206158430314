import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 商户区域分布情况
 */
export const complaintInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/complaint/complaintInfo',
        params
    })
}

/**
 * 投诉处罚情况统计
 */
export const complaintSupervision = (params) => {
    return $api.get({
        url: baseURL + '/api/complaint/complaintSupervision',
        params
    })
}

/**
 * 投诉受理新增趋势
 */
export const complaintAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/complaint/complaintAdd',
        params
    })
}

/**
 * 投诉受理累计势
 */
export const complaintCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/complaint/complaintCumulative',
        params
    })
}

/**
 * 投诉受理按城市/地区统计
 */
export const complaintStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/complaint/complaintStatistics',
        params
    })
}

/**
 * 投诉受理按领域/行业统计
 */
export const complaintIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/complaint/complaintIndustryStatistics',
        params
    })
}
